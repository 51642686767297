import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './scientificEngineering.style';

const ScientificEngineering = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        scientificEngineering {
          image {
            publicURL
          }
          title
          description
        }
      }
    }
  `);
  const {
    image,
    title,
    description,
  } = data.appModernJson.scientificEngineering;

  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
        <div className="image">
          <Image src={image.publicURL} alt="Built Logo" />
        </div>  
        <div className="content">
            <Heading content={title} />
            <Text content={description} />
          </div>
         
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ScientificEngineering;
