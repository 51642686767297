import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/Container';

import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
}) => {
  return (
    <Box {...sectionWrapper} as="section" id="quote">
      <NewsletterWrapper>
        <Container>
          <Box {...textArea}>
            <Heading content="Get a Free Quote" {...title} />
            <Text
              content="Please send us your name, email and a description of
               the project that you wish to get a quote on. 
               You can also contact us directly at oserrate@serratek.com."
              {...description}
            />
          </Box>
          <Box {...buttonArea}>
          <form method="POST" netlify-honeypot="bot-field" data-netlify="true" name="contact">
              <input type="hidden" name="form-name" value="contact" />
              <ContactFormWrapper>
                <Input
                  inputType="name"
                  label="Enter your name"
                  iconPosition="right"
                  isMaterial={true}
                  className="name_input"
                /> 
              </ContactFormWrapper>
              <ContactFormWrapper>
                <Input
                  inputType="email"
                  label="Email address"
                  iconPosition="right"
                  isMaterial={true}
                  className="email_input"
                />
              </ContactFormWrapper>
              <ContactFormWrapper>
                <textArea 
                name="message_input"
                rows="4"
                cols="57"
                placeholder="Enter your message"
                resize="none"
                >
                </textArea>
              </ContactFormWrapper>
              <ContactFormWrapper>
                <Button {...buttonStyle} type="submit" title="Send Message" />
              </ContactFormWrapper>
            </form>
          </Box>
        </Container>
      </NewsletterWrapper>
    </Box>
  );
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '80px', '100px'],
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    maxWidth: ['100%', '400px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
  },
};

export default Newsletter;
